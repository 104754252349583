<template>
    <div class="mx-5">
        <p class=" text-xl w-full text-center font-understock">
            {{$t('footer.extended.contact.title')}}
            <p class=" mt-2 leading-relaxed text-center text-base">
                {{$t('footer.extended.contact.adressLine1')}}<br>
                {{$t('footer.extended.contact.adressLine2')}}<br>
                {{$t('footer.extended.contact.phone1')}}<br>
                {{$t('footer.extended.contact.phone2')}}<br>
                <small>{{$t('footer.extended.contact.aviso')}}</small>
            </p>
            <SocialsLink class="mt-4" color="black" />
    </div>
</template>
<script>
import SocialsLink from "@/components/socials/SocialsLink.vue";

export default {
    name: "CorporateOffice",
    components: {
        SocialsLink
    },
}
</script>