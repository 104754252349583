<template>
    <div class="mx-5 md:mx-0 md:w-6/12 lg:w-4/12">
        <p class="text-sectionTitle text-center font-understock"> {{ $t('homepage.contact.title') }}</p>
        <p class="text-base text-center mx-auto md:mt-3 mt-0 lg:mt-4">{{ $t('homepage.contact.subtitle') }}</p>

        <form @submit.prevent="processForm" class="space-y-2 w-full md:mt-4 mt-2 lg:mt-6" id="messageForm">
            <div class="lg:flex w-full lg:space-x-3 lg:space-x-8">
                <iText id="firstName" name="firstName" :placeholder="$t('homepage.contact.inputs.firstName')"
                    ref="firstName" type="text" v-model="contact.firstName" />
                <iText id="lastName" name="lastName" :placeholder="$t('homepage.contact.inputs.lastName')"
                    ref="lastName" type="text" v-model="contact.lastName" />
            </div>

            <div class="lg:flex w-full lg:space-x-3 lg:space-x-8">
                <iText id="email" name="email" :placeholder="$t('homepage.contact.inputs.email')" ref="email"
                    type="email" v-model="contact.email" />
                <iText id="phone" name="phone" :placeholder="$t('homepage.contact.inputs.phone')" ref="phone" type="tel"
                    v-model="contact.phone" />
            </div>

            <iTextarea name="message" :placeholder="$t('homepage.contact.inputs.message')" ref="message"
                v-model="contact.message" id="message" />

            <div class="pb-2">
                <div id="check1Div">
                    <iCheckbox class="h-auto text-base" id="check1" name="terms" :text="$t('homepage.contact.inputs.agree')"
                        :privacy="$t('homepage.contact.inputs.privacy')" />
                </div>
                <div id="check2Div">
                    <iCheckbox class="h-auto text-base" id="check2" name="terms" :text="$t('homepage.contact.inputs.communications')" />
                </div>
            </div>
            <div class="pb-2" v-show="formError">
                <p class="text-sm text-red-400">* {{ $t('General.forms.error') }}</p>
            </div>
            <button 
                form="messageForm"
                type="submit"
                class="mt-3 bg-black hover:bg-bronze font-medium uppercase text-sm tracking-button inline-flex items-center justify-center relative transition duration-500 ease-in-out text-white w-full py-2 md:py-3">
                <span>{{ $t('buttons.sendMessage') }}</span>
                <div class="absolute right-3 flex justify-center">
                    <inline-svg fill="white" height="15" src="/svg/arrow/arrowGradient.svg" />
                </div>
            </button>

        </form>
        <div class="md:mt-4 mt-2">
            <div class="flex space-x-4 md:space-x-8 justify-center items-center mt-6">
                <a href='https://wa.me/351966764147' class="transform transition duration-500 hover:scale-110"
                    target="_blank">
                    <inline-svg fill="black" class="md:h-9 md:w-9 w-6 h-6" src="/svg/socials/whatsapp.svg" />
                </a>

                <a href='tel:Zoom351966764147' class="transform transition duration-500 hover:scale-110"
                    target="_blank">
                    <inline-svg fill="black" class="md:h-9 md:w-9 w-6 h-6" src="/svg/socials/zoom.svg" />
                </a>
            </div>
        </div>
    </div>
</template>


<script>
import iText from '@/components/contact/types/iText';
import iTextarea from '@/components/contact/types/iTextarea';
import iCheckbox from '@/components/contact/types/iCheckbox';

// Execution code
import mixin from '@/models/general/GeneralModel'

    export default {
    name: "Form",
    mixins: [mixin],
   components: {
            iText,
             iTextarea,
             iCheckbox,
              
        },
         data() {
            return {
                contact: {
                    firstnName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    message: "",
                    receive_comm: ""
                },
                formError: false
            }
        },
    methods:{
        async processForm(){
            const name = document.getElementById('firstName').value;
            this.contact.firstnName = document.getElementById('firstName').value
            this.contact.lastName = document.getElementById('lastName').value
            this.contact.email = document.getElementById('email').value
            this.contact.phone = document.getElementById('phone').value
            this.contact.message = document.getElementById('message').value
            this.contact.receive_comm = document.getElementById('check2').checked
            
            if(this.validateForm())
                return
           

            
            await this.saveMessage({data: this.contact, block: 'bookVisit'});
            this.$emit('thankYouMessage', name );

            // Formulário validado
            // Limpar dados
            // id: messageForm
            
            document.getElementById("messageForm").reset();

            // Há campos que com o reset não ficaram limpos, então vamos forçar manualmente usando os v-model
            // Limpando os v-model o problema fica resolvido
            this.contact.email = ''
            this.contact.phone = ''
            this.contact.lastName = ''

        },
        validateForm(){
            let invalid = false
            if(this.contact.firstnName == ""){
                document.getElementById('firstName').style="border-color: red;";
                invalid = true
            }

            if(this.contact.lastName == ""){
                document.getElementById('lastName').style="border-color: red;";
                invalid = true
            }

            if(this.contact.email == ""){
                document.getElementById('email').style="border-color: red;";
                invalid = true
            }

            if(this.contact.message == ""){
                document.getElementById('message').style="border-color: red;";
                invalid = true
            }

            if(!document.getElementById('check1').checked){
                document.getElementById('check1Div').style="color: red;";
                invalid = true
            }

            this.formError = invalid

            return invalid
        }
    }
}
</script>