<template>
    <div class="w-screen py-8 md:py-16 lg:py-24 flex flex-col items-center justify-center">
        <Form @thankYouMessage="thankyou"/> 
        <CorporateOffice class="block md:hidden mt-10" />
        <FormModal v-show="workModal" :noclose="true">
            <!-- begin::modal body -->
            <template v-slot:body>
                <ThankYouModal v-show="thankyouModal" @close="closeWorkModal" />
            </template>
            <!-- end::modal body -->
        </FormModal>
    </div>
</template>

<script>
    import Form from '@/components/contact/Form';
    import CorporateOffice from '@/layout/footer/CorporateOffice.vue';
    import FormModal from '@/components/modal/FormModal'
    import ThankYouModal from '@/components/modal/ThankYouModal'


    export default {
        name: "Contact",
          components: {
            Form,
            CorporateOffice,
            FormModal,
            ThankYouModal
        },
        data(){
          return{
              workModal: false,
              thankyouModal: false,
              thankYouName: ''
          }
        },
        methods:{
          thankyou(name){
            this.thankYouName = name;
            this.thankyouModal = true;
            this.workModal = true
          },
          closeWorkModal(){
              this.workModal = false
          },
        }
    };
</script>